<template>
  <section>
    <kn-auto-complete
      label="Estado"
      v-model="address.estado"
      :rules="[rules.required]"
      :items="states"
      @input="getZips(address.estado)"
    />
    <kn-auto-complete
      label="Ciudad"
      v-model="address.ciudad"
      :rules="mandatoryCityRules"
      :isMandatory="mandatoryCityRules.length > 0"
      :items="zips"
      item-text="nombre_ciudad"
      item-value="nombre_ciudad"
      @input="filterZipByCityOrMunicipality(address.ciudad, address.municipio)"
    />
    <kn-auto-complete
      label="Municipio"
      v-model="address.municipio"
      :rules="mandatoryMunicipalityRules"
      :isMandatory="mandatoryMunicipalityRules.length > 0"
      :items="zips"
      item-text="nombre_municipio"
      item-value="nombre_municipio"
      @input="filterZipByCityOrMunicipality(address.ciudad, address.municipio)"
    />
    <kn-auto-complete
      label="Código postal"
      v-model="address.codigo_postal"
      :rules="[rules.required]"
      :items="zipsByCity"
      item-text="codigo_postal"
      item-value="codigo_postal"
      @input="filterColByZip(address.codigo_postal)"
    />
    <kn-auto-complete
      label="Colonia"
      v-model="address.colonia"
      :rules="[rules.required]"
      :items="colonias"
      item-text="nombre_asentamiento"
      item-value="nombre_asentamiento"
      @input="getZipId()"
    />
    <kn-text-field
      label="Calle"
      v-model="address.calle"
      :rules="[rules.required]"
    />
    <kn-text-field
      label="Número exterior"
      v-model="address.numero_exterior"
      :rules="[rules.required]"
    />
    <kn-text-field
      label="Número interior"
      v-model="address.numero_interior"
      :rules="[]"
      :isMandatory="false"
    />
  </section>
</template>

<script>
import { api } from '@/api/axios-base'
import { estados } from '@/api/consts/estados'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import KnAutoComplete from '../inputs/KnAutoComplete.vue'
import KnTextField from '../inputs/KnTextField.vue'
export default {
  components: { KnAutoComplete, KnTextField },
  mixins: [validationFormMixin],
  props: {
    address: {
      type: Object,
      default: () => {}
    },
    fillFromSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      states: estados,
      zips: [],
      zipsByCity: [],
      colonias: []
    }
  },
  computed: {
    mandatoryCityRules() {
      return this.address.municipio ? [] : [this.rules.required]
    },
    mandatoryMunicipalityRules() {
      return this.address.ciudad ? [] : [this.rules.required]
    }
  },
  async created() {
    await this.fillAddress()
  },
  watch: {
    async fillFromSearch(val) {
      if (val) {
        await this.fillAddress()
      }
    },
    async address() {
      await this.fillAddress()
    }
  },
  methods: {
    async fetchZipByState(state) {
      try {
        const response = await api.get('/personas/api/codigo-postal-by-entidad/', {
          params: {
            entidad_federativa: state
          }
        })
        const responseData = await response.data
        // console.log('Codigo por estado: ', responseData);
        return responseData
      } catch (error) {
        console.error('Error al obtener codigos por entidad', error);
      }
    },
    
    async getZips(state) {
      try {
        this.zips = await this.fetchZipByState(state)
        // console.log('fetchZipsByState', this.zips);
      } catch (error) {
        console.error('Error al filtrar los codigos');
      }
    },
    filterZipByCity(city) {
      try {
        this.zipsByCity = this.zips.filter(zip => zip.nombre_ciudad === city)
        // console.log('filterZipByCity', this.zipsByCity);
      } catch (error) {
        console.error('Error al filtrar codigos por ciudad');
      }
    },
    filterZipByCityOrMunicipality(city, municipality = '') {
      try {
        this.zipsByCity = this.zips
          .filter(zip => zip.nombre_ciudad === city || zip.nombre_municipio === municipality)
        // console.log('filterZipByCity', this.zipsByCity);
      } catch (error) {
        console.error('Error al filtrar codigos por ciudad');
      }
    },
    filterColByZip(code) {
      try {
        this.colonias = this.zipsByCity.filter(zip => zip.codigo_postal === code)
        //console.log('filterColByZip: ', this.colonias);
      } catch (error) {
        console.error('Error al filtrar colonias por codigo postal');
      }
    },
    getZipId() {
      try {
        const zipsfilter = this.colonias.filter(zip => {
          return (zip.nombre_ciudad === this.address.ciudad 
                  || zip.nombre_municipio === this.address.municipio)
                && (zip.codigo_postal === this.address.codigo_postal 
                  || zip.codigo_postal === this.address.codigo_postal.codigo_postal)
                && zip.nombre_asentamiento === this.address.colonia
        })
        // console.log('zipFilter: ', zipsfilter);
        this.address.id_codigo_postal = zipsfilter.length  ? zipsfilter[0].id : null
        // console.log('zip id: ', this.address.id_codigo_postal);
      } catch (error) {
        console.error('Error al obtener zip id', error);
      }
    },
    async fillAddress() {
      if (this.address.estado 
        && this.address.estado !== null 
        && this.address.estado !== undefined 
        && this.address.estado !== '') {
          const cp = typeof this.address.codigo_postal === 'object' && this.address.codigo_postal !== null 
                      ? this.address.codigo_postal.codigo_postal 
                      : this.address.codigo_postal 
          // console.log('cp created: ', cp);
          await this.getZips(this.address.estado)
          this.filterZipByCityOrMunicipality(this.address.ciudad, this.address.municipio)
          this.filterColByZip(cp)
          this.getZipId()
      }
    }
  }
}
</script>
