<template>
  <section>
    <v-container>
      <v-row
        class="pt-15"
        justify="center"
        align="center"
      >
        <v-col
          cols="11"
          md="8"
          lg="8"
          xl="5"
        >
          <kn-form-title
            :title="title"
            :routerName="routerName"
          />
          <kn-tabs
            :items="dynamicTabs"
            @tabValue="setTabValue"
          />
          <kn-form-note-of-mandatory />
          
          <!-- Inicio datos basicos del proveedor -->
          <div v-if="valueDeterminate === 33">
            <kn-text-field
              class="mt-4"
              label="Nombre de proveedor"
              v-model="supplierName"
              :rules="[rules.required]"
            />

            <kn-form-subtitle title="Categorías" />
            <kn-auto-complete
              label="Categorías"
              v-model="supplierCategories"
              :rules="[rules.required]"
              multiple
              :items="categories"
              item-value="id"
              item-text="dato"
            />
            <kn-auto-complete
              label="Sub categorías"
              v-model="supplierSubcategories"
              :rules="[rules.required]"
              multiple
              :items="subcategories"
              item-value="id"
              item-text="dato"
            />
            <kn-form-subtitle title="" />
            <kn-check-box
              label="Completar datos personales?"
              v-model="showPersonalInfo"
              :rules="[]"
              :isMandatory="false"
            />
            <kn-check-box
              label="Completar datos fiscales?"
              v-model="showTaxInfo"
              :rules="[]"
              :isMandatory="false"
            />
          </div>
          <!-- Fin datos basicos del proveedor -->

          <!-- Inicio datos personales de representante de proveedor -->
          <div v-if="valueDeterminate === 66 && showPersonalInfo">
            <kn-personal-info :model="supplierPersonalInfo" />

            <kn-form-subtitle title="Dirección" />
            <kn-address
              class="mt-3"
              :address="supplierAddress"
              :fillFromSearch="fillAddressFrom"
            />

            <kn-form-subtitle title="Información de contacto" />
            <kn-contact-info :model="supplierPersonalInfo" />
          </div>
          <!-- Fin datos personales de representante de proveedor -->

          <!-- Inicio datos fiscales de proveedor -->
          <div v-if="valueDeterminate === 100 && showTaxInfo">
            <kn-tax-info :model="supplierTaxInfo" />
          </div>
          <!-- Fin datos fiscales de proveedor -->

          <kn-form-action-buttons
            @accept="save()"
            @cancel="cancel()"
          />
        </v-col>
      </v-row>
    </v-container>
    <kn-back-to-top-button />
    <kn-local-alert
      v-model="showAlert"
      :alertText="alertText" 
      :alertType="alertType"
      :alertColor="alertColor"
      :loadingAlert="loading"
      @action1="actionAlertBtn1"
      @action2="continueAdding"
    />
  </section>
</template>

<script>
import KnFormActionButtons from '@/components/KnFormActionButtons.vue'
import KnFormSubtitle from '@/components/KnFormSubtitle.vue'
import KnAddress from '@/components/forms/KnAddress.vue'
import KnContactInfo from '@/components/forms/KnContactInfo.vue'
import KnPersonalInfo from '@/components/forms/KnPersonalInfo.vue'
import KnTaxInfo from '@/components/forms/KnTaxInfo.vue'
import { utilsMixin } from '@/mixins/utilsMixin'
import { generalRepoMixin } from '@/mixins/generalRepoMixin'
import { addressUtilsMixin } from '@/mixins/addressUtilsMixin'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { api } from '@/api/axios-base'
import KnLocalAlert from '@/components/KnLocalAlert.vue'
import KnTabs from '@/components/KnTabs.vue'
import { mapState } from 'vuex'
import KnFormTitle from '@/components/KnFormTitle.vue'
import KnBackToTopButton from '@/components/forms/KnBackToTopButton.vue'
import KnFormNoteOfMandatory from '@/components/KnFormNoteOfMandatory.vue'
import KnTextField from '@/components/inputs/KnTextField.vue'
import KnAutoComplete from '@/components/inputs/KnAutoComplete.vue'
import KnCheckBox from '@/components/inputs/KnCheckBox.vue'
export default {
  components: {
    KnPersonalInfo,
    KnFormSubtitle,
    KnFormActionButtons,
    KnTaxInfo,
    KnAddress,
    KnContactInfo,
    KnLocalAlert,
    KnTabs,
    KnFormTitle,
    KnBackToTopButton,
    KnFormNoteOfMandatory,
    KnTextField,
    KnAutoComplete,
    KnCheckBox,
  },
  mixins: [
    utilsMixin,
    generalRepoMixin,
    addressUtilsMixin,
    validationFormMixin
  ],
  props: {
    entity: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      tabs: [
        { name: 'Información básica', value: 33 }
      ],
      valueDeterminate: 33,
      routerName: 'Proveedores',
      supplierName: '',
      showPersonalInfo: false,
      showTaxInfo: false,
      supplierPersonalInfo: {
        segundo_nombre: '',
        numero_identidad: '',
        id_institucion_educativa: null,
      },
      supplierAddress: {
        id: null,
        numero_interior: ''
      },
      supplierTaxInfo: {
        id: null
      },
      supplierId: null,
      loading: false,
      fillAddressFrom: false,
      photoDoc: null,
      supplierPhoto: null,
      supplierCategories: [],
      supplierSubcategories: [],
      bkSupplierCategories: [],
      bkSupplierSubcategories: [],
      categories: [],
      subcategories: []
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    title() {
      return this.entity === null ? 'Agregar proveedor' : 'Editar proveedor'
    },
    isNewMode() {
      return (this.entity === null)
    },
    successAlertText() {
      return this.isNewMode ? 'Proveedor registrado con exito!' : 'Proveedor actualizado con exito!'
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info'
    },
    dynamicTabs() {
      if (this.showPersonalInfo && this.showTaxInfo) {
        return [
          { name: 'Información básica', value: 33, percentage: 33 },
          { name: 'Información representante', value: 66, percentage: 66 },
          { name: 'Datos fiscales', value: 100, percentage: 100},
        ]
      } else if (this.showPersonalInfo) {
        return [
          { name: 'Información básica', value: 33, percentage: 50 },
          { name: 'Información representante', value: 66, percentage: 100 }
        ]
      } else if (this.showTaxInfo) {
        return [
          { name: 'Información básica', value: 33, percentage: 50 },
          { name: 'Datos fiscales', value: 100, percentage: 100 },
        ]
      }
      return [ { name: 'Información básica', value: 33, percentage: 100 } ]
    }
  },
  async created() {
    this.categories = await this.fetchSupplierCategoriesByEI(this.institutionId)
    this.subcategories = await this.fetchSupplierSubCategoriesByEI(this.institutionId)
    if (!this.isNewMode) {
      this.fillData()
    } else {
      this.supplierPersonalInfo.id_institucion_educativa = this.institutionId
    }
  },
  methods: {
    async fetchSupplierCategoriesByEI(institutionId) {
      try {
        const response = await api.get(`/personas/api/categoria-proveedor-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('No se pudieron obtener las categorias de proveedor')
          return 
        }
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener categorias de proveedor por institucion educativa');
      }
    },
    async fetchSupplierSubCategoriesByEI(institutionId) {
      try {
        const response = await api.get(`/personas/api/sub-categoria-proveedor-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('No se pudieron obtener las sub categorias de proveedor')
          return 
        }
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener sub categorias de proveedor por institucion educativa');
      }
    },
    async postSupplier(supplierObj) {
      try {
        const response = await api.post('/personas/crear-proveedor', supplierObj)
        const responseData = await response.data
        // console.log('Respuesta proveedor: ', responseData)
        if (responseData.e) {
          this.errors.push('No se pudo crear el proveedor: ' + responseData.e.join(','))
          return
        }
        supplierObj.id = responseData.id_objeto
        this.supplierId = responseData.id_objeto
      } catch (error) {
        this.errors.push('Error al intentar crear proveedor')
        console.error('Error al intentar crear proveedor.', error);
      }
    },
    async patchSupplier(supplierObj) {
      try {
        const response = await api.patch('/personas/update-proveedor', supplierObj)
        const responseData = await response.data
        // console.log('Respuesta proveedor: ', responseData)
        if (responseData.e) {
          this.errors.push('No se pudo actualizar el proveedor: ' + responseData.e.join(','))
          return
        }
        supplierObj.id = responseData.id_objeto
        this.supplierId = responseData.id_objeto
      } catch (error) {
        this.errors.push('Error al intentar actualizar proveedor')
        console.error('Error al intentar actualizar proveedor.', error);
      }
    },
    async assignCategoryToSupplier(categoryId, supplierId) {
      try {
        const response = await api.post('/personas/agregar-categoria-proveedor', {
          id_proveedor: supplierId,
          id_categoria: categoryId
        })
        const responseData = response.data
        if (responseData.e) {
          this.errors.push('No se pudo asignar categoria al proveedor: ' + responseData.e.join(', '))
        }
        return responseData
      } catch (error) {
        console.error('Error al intentar asignar categoria a proveedor');
      }
    },
    async assignSubCategoryToSupplier(subCategoryId, supplierId) {
      try {
        const response = await api.post('/personas/agregar-sub-categoria-proveedor', {
          id_proveedor: supplierId,
          id_sub_categoria: subCategoryId
        })
        const responseData = response.data
        if (responseData.e) {
          this.errors.push('No se pudo asignar categoria al proveedor: ' + responseData.e.join(', '))
        }
        return responseData
      } catch (error) {
        console.error('Error al intentar asignar categoria a proveedor');
      }
    },
    // agregar-datos-fiscales-proveedor
    async assignTaxInfoToSupplier(taxInfoId, supplierId) {
      try {
        const response = await api.post('/personas/agregar-datos-fiscales-proveedor', {
          id_proveedor: supplierId,
          id_datos_fiscales: taxInfoId
        })
        const responseData = response.data
        if (responseData.e) {
          this.errors.push('No se pudo asignar datos fiscales al proveedor: ' + responseData.e.join(', '))
        }
        return responseData
      } catch (error) {
        console.error('Error al intentar asignar categoria a proveedor');
      }
    },
    async createSupplier() {
      try {
        this.alertText = 'Creando proveedor...'
        /**
         * Los datos personales solo se van a crear si es
         * que se marca el checkbox para completar
         * datos personales
         */
        if (this.showPersonalInfo) {
          this.supplierAddress.numero_contacto = this.supplierPersonalInfo.telefono_movil
          this.supplierAddress.id_institucion_educativa = this.institutionId
          this.alertText = 'Creando dirección de proveedor'
          await this.postAddress(this.supplierAddress)
          // console.log('Direccion proveedor: ', this.supplierAddress)

          this.supplierPersonalInfo.id_institucion_educativa = this.institutionId
          this.supplierPersonalInfo.id_direccion = this.supplierAddress.id
          this.supplierPersonalInfo.telefono_casa = this.supplierPersonalInfo.telefono_movil
          this.alertText = 'Creando datos personales de proveedor'
          await this.postPersonalInfo(this.supplierPersonalInfo)
          // console.log('Datos personales proveedor: ', this.supplierPersonalInfo)
        }
        
        /**
         * Los datos fiscales solo se van a crear si es
         * que se marca el checkbox para completar
         * datos fiscales
         */
        if (this.showTaxInfo) {
          this.alertText = 'Creando datos fiscales de proveedor'
          await this.postTaxInfo(this.supplierTaxInfo)
          // console.log('Datos fiscales proveedor: ', this.supplierTaxInfo)
        }

        let userName = ''
        let userPassword = ''
        let name = this.supplierPersonalInfo.primer_nombre
        let lastName = this.supplierPersonalInfo.apellido_paterno
        let email = ''

        if (this.showPersonalInfo) {
          userName = this.generateUsername(this.supplierPersonalInfo)
          userPassword = this.generatePassword(this.supplierPersonalInfo)
          email = this.supplierPersonalInfo.email
        } else {
          const supplierName = this.separateStringInTwo(this.supplierName)
          userName = this.generateGenericUsername(supplierName, 'PROVEEDOR')
          userPassword = this.generateGenericPassword() 
          name = supplierName.firstName
          lastName = supplierName.lastName
        }

        const userObj = {
          nombre_usuario: userName,
          password: userPassword,
          correo: email,
          nombre: name,
          apellido_paterno: lastName,
          id: null,
          is_staff: false
        }
        this.alertText = 'Creando usuario de proveedor'
        await this.postUser(userObj)
        // console.log('Usuario proveedor: ', userObj);
        
        this.alertText = `Usuario proveedor: ${userObj.nombre_usuario} ${userObj.password}`

        const supplierObj = {
          id_usuario: userObj.id,
          nombre_proveedor: this.supplierName,
          id_datos_personales: this.showPersonalInfo ? this.supplierPersonalInfo.id : null,
          id_datos_fiscales: this.showTaxInfo ? this.supplierTaxInfo.id : null,
          id_institucion_financiera: this.institutionId
        }
        await this.postSupplier(supplierObj)
        // console.log('Proveedor: ', supplierObj)

        // console.log('categorias seleccionadas:', this.supplierCategories);
        // console.log('Sub categorias seleccionadas:', this.supplierSubcategories)
        if (this.supplierId !== null) {
          if (this.supplierCategories.length > 0) {
            for (const category of this.supplierCategories) {
              await this.assignCategoryToSupplier(category, this.supplierId)
            }
          }
          if (this.supplierSubcategories.length > 0) {
            for (const subcategory of this.supplierSubcategories) {
              await this.assignSubCategoryToSupplier(subcategory, this.supplierId)
            }
          }
        }
        
      } catch (error) {
        this.errors.push('Error al intentar crear proveedor')
        console.error('Error al crear proveedor.', error);
      }
    },
    async updateSupplier() {
      try {
        this.alertText = 'Actualizando proveedor...'
        this.supplierAddress.numero_contacto = this.supplierPersonalInfo.telefono_movil
        this.supplierAddress.id_institucion_educativa = this.institutionId

        this.supplierPersonalInfo.id_institucion_educativa = this.institutionId
        this.supplierPersonalInfo.telefono_casa = this.supplierPersonalInfo.telefono_movil
        
        if (this.supplierAddress.id !== null && this.supplierPersonalInfo.id !== null) {
          this.alertText = 'Actualizando dirección de proveedor'
          await this.updateAddress(this.supplierAddress)

          this.supplierPersonalInfo.id_direccion = this.supplierAddress.id
          this.alertText = 'Actualizando datos personales de proveedor'
          await this.updatePersonalInfo(this.supplierPersonalInfo)
        } else if (this.showPersonalInfo) {
          this.alertText = 'Creando dirección de proveedor'
          await this.postAddress(this.supplierAddress)

          this.supplierPersonalInfo.id_direccion = this.supplierAddress.id
          this.alertText = 'Creando datos personales de proveedor'
          await this.postPersonalInfo(this.supplierPersonalInfo)
        }

        if (this.supplierTaxInfo.id !== null) {
          this.alertText = 'Actualizando datos fiscales de proveedor'
          await this.updateTaxInfo(this.supplierTaxInfo)
        } else if (this.showTaxInfo) {
          this.alertText = 'Creando datos fiscales de proveedor'
          await this.postTaxInfo(this.supplierTaxInfo)

          this.alertText = 'Asignando datos fiscales a proveedor'
          await this.assignTaxInfoToSupplier(this.supplierTaxInfo.id, this.supplierId)
        }

        const supplierObj = {
          id: this.supplierId,
          id_usuario: this.entity.usuario.id,
          nombre_proveedor: this.supplierName,
          id_datos_personales: this.showPersonalInfo ? this.supplierPersonalInfo.id : null,
          id_institucion_educativa: this.institutionId
        }
        this.alertText = "Actualizando proveedor"
        await this.patchSupplier(supplierObj)
        // console.log('Proveedor: ', supplierObj)

        /** Nos aseguramos de conocer cuales categorias
         * y sub categorias se han agregado y serán las 
         * que se asignen al proveedor en la actualización
         */
        const supplierCategories = this.supplierCategories.filter(category => !this.bkSupplierCategories.includes(category))
        const supplierSubcategories = this.supplierSubcategories.filter(subcategory => !this.bkSupplierSubcategories.includes(subcategory))

        if (this.supplierId !== null) {
          if (supplierCategories.length > 0) {
            for (const category of supplierCategories) {
              await this.assignCategoryToSupplier(category, this.supplierId)
            }
          }
          if (supplierSubcategories.length > 0) {
            for (const subcategory of supplierSubcategories) {
              await this.assignSubCategoryToSupplier(subcategory, this.supplierId)
            }
          }
        }
      } catch (error) {
        this.errors.push('Error al intentar actualizar proveedor')
        console.error('Error al intentar actualizar proveedor.', error);
      }
    },
    async createObjects() {
      try {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true
        await this.createSupplier()
        
        if (this.errors.length) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = this.successAlertType
          this.alertColor = 'success'
          this.alertText = this.successAlertText
        }
        this.loading = false
      } catch (error) {
        console.error('Error al dar de alta Proveedor y objetos relacionados', error);
      }
    },
    async updateObjects() {
      try {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true
        await this.updateSupplier()

        this.loading = false
        if (this.errors.length > 0) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = this.successAlertType
          this.alertColor = 'success'
          this.alertText = this.successAlertText
        }
      } catch (error) {
        this.loading = false
        // console.log('Error al actualizar Proveedor y objetos relacionados', error);
        this.errors.push('Error al actualizar Proveedor y objetos relacionados')
        this.alertType = 'error'
        this.alertText = this.errors.join(', ')

      }
    },
    setTabValue(val) {
      this.valueDeterminate = val
    },
    async save() {
      if (this.isNewMode) {
        this.createObjects()
      } else {
        this.updateObjects()
      }
    },
    cancel() {
      this.returnToTable()
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType  === 'info') {
        this.returnToTable()
      } else {
        this.closeAlert()
      }
    },
    continueAdding() {
      this.clean()
      this.closeAlert()
    },
    returnToTable() {
      this.$router.replace({name: this.routerName})
    },
    closeAlert() {
      this.errors = []
      this.showAlert = false
    },
    clean() {
      window.scroll({
        top: 50,
        left: 0,
        behavior: 'smooth'
      });
      this.valueDeterminate = 33
      this.supplierPersonalInfo = {
        segundo_nombre: '',
        numero_identidad: ''
      }
      this.supplierAddress = {
        id: null,
        ciudad: '',
        numero_interior: ''
      }
      this.supplierTaxInfo = {
        id: null
      }
      this.supplierId = null
    },
    async fillData() {
      this.supplierId = this.entity.id
      this.supplierName = this.entity.nombre_proveedor
      if (this.entity.datos_personales !== null) {
        this.supplierPersonalInfo = {...this.entity.datos_personales}
        this.supplierPersonalInfo.id_genero = this.supplierPersonalInfo.genero
        this.supplierPersonalInfo.id_institucion_educativa = this.entity.institucion_educativa.id
        this.fillAddressFrom = false
        const supplierAddress = await this.fetchAddresById(this.supplierPersonalInfo.direccion, this.institutionId)
        this.supplierAddress = {...supplierAddress}
        this.supplierAddress.id_codigo_postal = supplierAddress.codigo_postal.id
        this.supplierAddress.municipio = supplierAddress.codigo_postal.nombre_municipio
        this.fillAddressFrom = true

        this.showPersonalInfo = true
      }
      if (this.entity.datos_fiscales.length > 0) {
        this.supplierTaxInfo = {...this.entity.datos_fiscales[0]}
        this.supplierTaxInfo.id_uso_factura = this.supplierTaxInfo.uso_factura

        this.showTaxInfo = true
      }

      this.entity.categorias.forEach(category => {
        this.supplierCategories.push(category.id)
        this.bkSupplierCategories.push(category.id)
      })

      this.entity.sub_categorias.forEach(subcategory => {
        this.supplierSubcategories.push(subcategory.id)
        this.bkSupplierSubcategories.push(subcategory.id)
      })
    },
    /** Metodos para foto **/
    savePhoto(file) {
      this.photoDoc = file
    },
    clearPhoto() {
      this.photoDoc = null
    },
    clearImage() {
      this.supplierPhoto = null
      this.supplierPersonalInfo.url_foto = ''
    },
    separateStringInTwo(initialString) {
      const splitedString = initialString.split(' ')
      const numberOfWords = splitedString.length
      let mediumValue = 0
      let item = {
        firstName: '',
        lastName: ''
      }
      if (numberOfWords === 1) {
        item.firstName = splitedString[0]
      }
      if (numberOfWords >= 2) {
        mediumValue = numberOfWords % 2 === 0 ? (numberOfWords / 2) : (numberOfWords / 2) + 1
        item.firstName = splitedString.slice(0, mediumValue).join(' ')
        item.lastName = splitedString.slice(mediumValue, numberOfWords).join(' ')
      }
      return item
    }
  }
}
</script>

<style>

</style>